<!-- template for the modal component -->
<template>
  <div class="sticky top-0" style="z-index: 10">
    <!-- <header
      class="bg-sky-600 text-white shadow text-center"
      v-if="$route.meta.title"
    >
      <div class="px-1 py-2.5 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <p
          @click="counter = 0"
          class="text-sm text-center font-medium leading-tight"
        >
          WalletConnect announces v2.0!
          <br class="md:hidden" />
          <a href="" class="underline px-2 font-light"> Read more</a>
        </p>
      </div>
    </header> -->

    <header
      class="item-center border-gray-300 border-opacity-25 bg-white shadow-lg"
      v-if="$route.meta.title"
    >
      <div
        class="item-center mx-auto max-w-7xl px-8 py-6 sm:px-6 md:flex lg:px-8"
      >
        <div
          class="mx-auto flex max-w-7xl items-center justify-between sm:px-6 lg:px-8"
        >
          <div>
            <router-link to="/">
              <a
                class="title-font flex items-center font-medium text-gray-900 md:mb-0"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="h-10 w-10 rounded-full bg-blue-600 p-2 text-white"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"
                  ></path>
                </svg>
                <span class="ml-3 text-xl">BlockMaster</span>
              </a></router-link
            >
          </div>
          <router-link to="/"></router-link>

          <!-- Mobile menu button -->
          <div class="flex md:hidden">
            <button
              v-if="!showModal"
              type="button"
              @click="showModal = !showModal"
              class="text-gray-500 hover:text-gray-600 focus:text-gray-600 focus:outline-none"
              aria-label="toggle menu"
            >
              <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div class="hidden w-full md:flex md:items-center md:justify-between">
          <div
            class="-mx-4 flex flex-col px-2 py-3 md:mx-0 md:flex-row md:py-0"
          ></div>

          <div class="relative">
            <a
              href="#"
              class="rounded p-2 py-1 text-lg text-gray-900 transition-colors duration-200 hover:text-blue-600 md:mx-2"
              >Home</a
            >

            <a
              href="#"
              class="rounded p-2 py-1 text-lg text-gray-900 transition-colors duration-200 hover:text-blue-600 md:mx-2"
              >Contact us</a
            >
            <a
              href="#"
              class="rounded p-2 py-1 text-lg text-gray-900 transition-colors duration-200 hover:text-blue-600 md:mx-2"
              >FAQ</a
            >
          </div>
        </div>
      </div>
    </header>
  </div>
  <div class="modal-mask backdrop-blur-sm" v-if="showModal">
    <div class="modal-wrapper">
      <div
        class="modal-container item-center rounded-lg p-5"
        style="position: fixed; top: 25px; right: 20px; left: 20px"
      >
        <div class="modal-header">
          <div class="item-center flex justify-between">
            <div>
              <router-link to="/">
                <span class="text-xl font-bold underline"> </span
              ></router-link>
            </div>

            <button @click="showModal = false" class="focus:bg-blue-100">
              <svg
                stroke="currentColor"
                fill="none"
                stroke-width="0"
                viewBox="0 0 24 24"
                aria-hidden="true"
                font-size="1.5rem"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <div class="modal-body py-6">
          <a
            href="#"
            class="m-4 rounded text-lg text-gray-900 transition-colors duration-200 hover:text-blue-600 md:mx-2"
            >Home</a
          >

          <br />
          <a
            href="#"
            class="m-4 rounded text-lg text-gray-900 transition-colors duration-200 hover:text-blue-600 md:mx-2"
            >Contact us</a
          >
          <br />
          <a
            href="#"
            class="m-4 rounded text-lg text-gray-900 transition-colors duration-200 hover:text-blue-600 md:mx-2"
            >FAQ</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- app -->

  <!-- use the modal component, pass in the prop -->
</template>
<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 20;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: table;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 330px;
  margin: 0px auto;

  background-color: #fff;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
